import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { PublishedEventListItem, StyleObj } from '../../../@types';
import useBreakpoints from '../../../hooks/useBreakpoints';
import useLocalization from '../../../hooks/useLocalization';
import TotalMarketsLink from '../../atoms/TotalMarketsLink';
import ParticipantsCell from './ParticipantsCell';
import FavoritesCheckbox from '../../atoms/FavoritesCheckbox';
import useMobileOnHold from '../../../hooks/useMobileOnHold';

const makeStyles = (inPlay = false): StyleObj => ({
  container: {
    flex: 1,
    gap: {
      xs: 0.5,
      md: 1,
    },
    width: '100%',
    height: '100%',
  },
  eventPart: {
    maxWidth: {
      xs: 75,
      md: '100%',
    },
    typography: {
      xs: 'body4',
      md: 'body3',
    },
  },
  timeAndMarketsWrapper: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    '> p': {
      typography: {
        xs: 'body4',
        md: 'body3',
      },
    },
  },
  mobileInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
    alignItems: 'center',
  },
  timeText: {
    color: 'neutral.300',
    typography: {
      xs: 'body4',
      md: 'body3',
    },
  },
});

const EventDetailsCell = ({
  event,
  inPlay,
  isChecked,
}: {
  event: PublishedEventListItem;
  inPlay?: boolean;
  isChecked?: boolean;
}) => {
  const { currentEventPart, totalMarkets, startDate, id, matchTime } = event;

  const { sportId } = useParams<{ sportId: string }>();
  const { isTablet } = useBreakpoints();
  const { handleFavorite } = useMobileOnHold(!!event.favourite, 'events', event.id);
  const { isMobile } = useBreakpoints();

  const { getLocalizedEventPart } = useLocalization();

  const eventPartToDisplay = currentEventPart?.shortName ?? getLocalizedEventPart(currentEventPart?.name);
  const styles = makeStyles(inPlay);

  const showTimeAndMarkets = inPlay || isTablet;

  return (
    <Stack sx={styles.container}>
      <ParticipantsCell event={event} inPlay={inPlay} />

      {showTimeAndMarkets && (
        <Stack sx={styles.mobileInfo}>
          {isChecked && isMobile && (
            <FavoritesCheckbox
              variant='small'
              isChecked={isChecked}
              onChange={handleFavorite}
              onClick={(e) => e.stopPropagation()}
              sx={{ padding: 0, height: '14px', width: '14px' }}
            />
          )}
          {inPlay && matchTime && <Typography sx={styles.timeText}>{matchTime}</Typography>}
          {!inPlay && <Typography sx={styles.timeText}>{dayjs(startDate).format('HH:mm')}</Typography>}
          {eventPartToDisplay && sportId && (
            <Typography sx={styles.eventPart} noWrap color='info.100'>
              {eventPartToDisplay}
            </Typography>
          )}
          <TotalMarketsLink eventId={id} totalMarkets={totalMarkets || 0} inPlay={inPlay} />
        </Stack>
      )}
    </Stack>
  );
};

export default EventDetailsCell;

import { Stack, Typography } from '@mui/material';
import HighlightedParticipantIndicator from '../../atoms/HighlightedParticipantIndicator';
import InPlayScoreChangeIndicator from './InPlayScoreChangeIndicator';
import useScoreChange from '../../../hooks/useScoreChange';
import { PublishedEventListItem } from '../../../@types';

const ParticipantInfo = ({ event, side }: { event: PublishedEventListItem; side: 'home' | 'away' }) => {
  const { scoreChanged } = useScoreChange(event, side);

  const participant = event.participants[side];
  const isHighlighted = participant.isHighlighted;

  return (
    <Stack direction='row' alignItems='center' sx={{ width: '100%' }}>
      {isHighlighted != null && <HighlightedParticipantIndicator isHighlighted={isHighlighted} />}
      <Typography
        noWrap
        sx={{
          typography: {
            xs: 'body3',
            md: 'body2',
          },
          fontWeight: {
            xs: 600,
            md: 600,
          },
          letterSpacing: { xs: 0, md: 0.5 },
        }}
      >
        {participant?.name || participant?.shortName}
      </Typography>
      <InPlayScoreChangeIndicator isVisible={scoreChanged} />
    </Stack>
  );
};

export default ParticipantInfo;

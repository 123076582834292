import { Button, ButtonProps } from '@mui/material';
import useLocalization from '../../hooks/useLocalization';

const styles = {
  button: {
    width: '100%',
    maxWidth: 500,
    minHeight: 0,
    height: 30,
    backgroundColor: 'neutral.500',
    color: 'text.primary',
    borderRadius: 1,
    mx: 1,
    typography: 'body3',
    '&:hover': {
      backgroundColor: 'neutral.400',
    },
  },
};

const BackToTopButton = (props: ButtonProps) => {
  const { t } = useLocalization();

  const scrollToTop = () => {
    const mainElement = document.querySelector('main');
    const targetElement = mainElement || document.body;

    targetElement.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Button onClick={scrollToTop} variant='text' sx={styles.button} {...props}>
      {t('backToTop')}
    </Button>
  );
};

export default BackToTopButton;

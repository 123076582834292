import { Box, Typography } from '@mui/material';
import { SanityDocument } from '@sanity/client';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { StyleObj } from '../../@types';
import useBreakpoints from '../../hooks/useBreakpoints';
import { useSanity } from '../../hooks/useSanity';
import BackToTopButton from '../atoms/BackToTopButton';
import StyledSkeleton from '../atoms/StyledSkeleton';
import Logo from '../icons/Logo';

const styles: StyleObj = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'neutral.600',
    gap: 2,
    px: 2,
    pt: {
      xs: 2,
      md: 4,
    },
    pb: 4,
  },
  logoWrapper: {
    mx: 'auto',
    filter: 'grayscale(100%)',
  },
  title: {
    color: 'primary.100',
    typography: {
      xs: 'body3',
      md: 'body2',
    },
  },
};

const Footer = () => {
  const { data: cmsContent, isLoading } = useSanity<SanityDocument>('PortableTextContent');
  const { isMobile } = useBreakpoints();

  const determineLinkForwarding = (item: SanityDocument) => {
    if (item?.callToActionURL) {
      return item.callToActionURL;
    }
    return `/content/${item.path}`;
  };

  if (isLoading) {
    return <StyledSkeleton width={'100%'} height={100} />;
  }

  return (
    <Box sx={styles.container}>
      {isMobile && (
        <>
          <BackToTopButton />
          <Box sx={styles.logoWrapper}>
            <Logo />
          </Box>
        </>
      )}

      <Typography sx={styles.title}>
        {cmsContent?.map((item, index) => {
          return (
            <Fragment key={item._id}>
              {index > 0 && ' | '}
              <Box
                component={Link}
                to={determineLinkForwarding(item)}
                sx={{ textDecoration: 'inherit', color: 'inherit' }}
              >
                {item.title}
              </Box>
            </Fragment>
          );
        })}
      </Typography>
    </Box>
  );
};

export default Footer;

import { Box } from '@mui/material';
import { PublishedEventListItem, StyleObj } from '../../../@types';
import ParticipantInfo from './ParticipantInfo';
import { Link } from 'react-router-dom';
import { getEventDetailLink } from '../../../helpers/navigation';

const styles: StyleObj = {
  participants: {
    textDecoration: 'none',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: {
      xs: 0.5,
      md: 1,
    },
    '& p': {
      maxWidth: '95%',
    },
    '&:hover': {
      color: 'info.main',
    },
  },
};

const ParticipantsCell = ({ event, inPlay }: { event: PublishedEventListItem; inPlay?: boolean }) => {
  return (
    <Box sx={styles.participants} flex={1} component={Link} to={getEventDetailLink(event.id, inPlay)}>
      <ParticipantInfo event={event} side='home' />
      <ParticipantInfo event={event} side='away' />
    </Box>
  );
};

export default ParticipantsCell;

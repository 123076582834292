import { Container, CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import { PropsWithChildren, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useBetslip } from '../../contexts/BetslipContext';
import useBreakpoints from '../../hooks/useBreakpoints';
import useWinningJackpot from '../../hooks/useWinningJackpot';
import DrawerNavigation from '../molecules/sidebar/DrawerNavigation';
import Header from '../molecules/Header';
import JackpotAnimation from '../molecules/JackpotAnimation';
import BetslipDrawer from '../organisms/BetslipDrawer';
import CMSSideContent from '../organisms/CMSSideContent';
import ReofferedBetslipDrawer from '../organisms/ReofferedBetslipDrawer';
import { dynamicViewportHeight } from '../../styles/mixins';
import JackpotList from '../molecules/JackpotList';

const styles = {
  container: { display: 'flex', width: '100%' },
  main: {
    flexGrow: 1,
    overflowX: 'hidden',
    ...dynamicViewportHeight(),
  },
  sideContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 270px',
    pt: 8,
    overflow: 'auto',
  },
};

const MainLayout = ({ children }: PropsWithChildren) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { isMobile, isTablet } = useBreakpoints();
  const { bets } = useBetslip();
  const makeSpaceForBetSlip = bets.length > 0 && isMobile;

  const { clearJackpot, winningJackpot } = useWinningJackpot();

  const location = useLocation();
  const isSportsPage = location.pathname.includes('/sports');
  const isMyBetsPage = location.pathname.includes('/my-bets');
  const showJackpotList = isSportsPage && location.pathname !== '/sports';
  const showSideContent = (isSportsPage || isMyBetsPage) && !isTablet;

  const toggleDrawerVisibility = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  };

  const isCasinoRoute = location.pathname === '/casino';
  const fullWidth = isCasinoRoute;

  return (
    <Container
      disableGutters
      sx={{
        ...styles.container,
        maxWidth: fullWidth ? '100vw !important' : '2400px !important',
        mx: fullWidth ? 0 : 'auto',
      }}
    >
      <CssBaseline />
      <Header toggleDrawerVisibility={toggleDrawerVisibility} />
      <DrawerNavigation isOpen={isDrawerOpen} toggleVisibility={toggleDrawerVisibility} />
      {!!winningJackpot && <JackpotAnimation winningJackpotPool={winningJackpot} clearJackpot={clearJackpot} />}
      <Box
        width={1}
        component='main'
        sx={{
          ...styles.main,
          px: 0,
          pt: 8,
          pb: makeSpaceForBetSlip ? 4.5 : 0,
        }}
      >
        {children}
      </Box>
      {showSideContent && (
        <Box sx={styles.sideContentContainer}>
          {showJackpotList && <JackpotList overflow='auto' />}
          <CMSSideContent />
        </Box>
      )}
      <BetslipDrawer />
      <ReofferedBetslipDrawer />
    </Container>
  );
};

export default MainLayout;
